import { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './select.module.scss';
import Button from '@/components/atoms/Button';
import useOutsideClick from '@/hooks/useOutsideClick';
import OptionItem from '@/components/atoms/Select/OptionItem';
import Text from '@/components/atoms/Text';
import variables from '@/styles/variables';
import useDeviceType from '@/hooks/useDeviceType';

const Select = (props) => {
  const {
    id,
    options,
    bgColor = '#ffffffdf',
    br = 'soft',
    title = '',
    name = '',
    titleColor = variables['color-white'],
    brColor = 'transparent',
    color = 'var(--color-white-absolute)',
    error = '',
    variant = 'filled',
    width = 'fullWidth',
    defaultValue = '',
    onChange = () => {},
    onSelect = () => {},
    className = '',
    required = false,
    placeholder = '',
    shadow = false,
    relative = false,
    disable = false,
    textSize = 's',
    weight = 'light'
  } = props;

  const selectRef = useRef();
  const listRef = useRef();
  const { isDesktop } = useDeviceType();

  const defaultValueName = useMemo(
    () => !placeholder && options.find((o) => o.value === defaultValue)?.name,
    [defaultValue]
  );

  const [open, setOpen] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const [selected, setSelected] = useState(
    () => options.find((x) => x.value === defaultValue) || null
  );

  // este useEffect vuelve el selected a 0 si las opciones del selector cambiaron
  useEffect(() => {
    if (options.filter((x) => x.id === selected?.id).length < 1) {
      setSelected(options.find((o) => o.id === defaultValue) || null);
    }
  }, [options]);
  useEffect(() => {
    setSelected(options.find((o) => o.id === defaultValue) || null);
  }, [placeholder]);
  useEffect(() => {
    if (onSelect) {
      const _option = options.find((x) => x.id === defaultValue);
      if (_option) {
        onSelect(_option);
      }
    }
  }, [defaultValue]);
  const selectClass = cn({
    [styles.container]: true,
    [styles[variant]]: true,
    [className]: !!className,
    [styles.brHard]: br === 'hard',
    scrollBarStyles: true
  });

  const contentClass = cn({
    [styles.content]: true,
    [styles['content--visible']]: open,
    [styles.relativeContent]: !isDesktop && relative
  });

  const widthOptions = {
    fullWidth: '100%',
    none: 'auto',
    sm: '25%',
    md: '50%',
    lg: '75%'
  };

  useOutsideClick(selectRef, () => {
    setOpen(false);
  });

  const handleSelect = (x) => {
    setSelected(x);
    setOpen(false);
    onChange(x);
    onSelect(x);
  };

  const renderOptions = () => {
    const opts = options.map((x) => (
      <OptionItem
        key={x.value}
        label={x.name}
        onClick={() => handleSelect(x)}
      />
    ));
    return opts;
  };

  const handleScroll = () => {
    setOpacity(
      1 -
        listRef.current.scrollTop /
          (listRef.current.scrollHeight - listRef.current.offsetHeight)
    );
  };

  return (
    <div
      className={selectClass}
      ref={selectRef}
      name={name}
    >
      {title && (
        <Text
          className={styles.title}
          textColor={error ? '#d9585e' : titleColor}
          textSize={textSize}
          weight={weight}
        >
          {title}
          {required ? ' *' : ''}
        </Text>
      )}

      <Button
        id={id}
        label={selected?.name || defaultValueName || placeholder}
        variant="primary"
        endIcon={!disable && <IoIosArrowDown style={{ fontSize: '26px' }} />}
        justify="space-between"
        onClick={() => !disable && setOpen(!open)}
        textColor={color}
        style={{
          textAlign: 'left',
          width: `${widthOptions[width]}`,
          backgroundColor: variant === 'outlined' ? 'transparent' : bgColor,
          border: `1px solid ${brColor}`,
          cursor: disable ? 'not-allowed' : 'pointer',
          color
        }}
        textStyle={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%'
        }}
      />
      <div
        style={{
          width: `${widthOptions[width]}`,
          overflowY: 'hidden',
          maxHeight: '22rem'
        }}
        className={contentClass}
      >
        <ul
          className={styles.list}
          onScroll={shadow ? handleScroll : null}
          ref={listRef}
        >
          {renderOptions()}
        </ul>
        {!!shadow && (
          <div
            className={styles.shadow}
            style={{ opacity }}
          />
        )}
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

Select.propTypes = {
  bgColor: PropTypes.string,
  br: PropTypes.oneOf(['soft', 'hard']),
  brColor: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'fullWidth']),
  options: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined']),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  titleColor: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  shadow: PropTypes.bool,
  relative: PropTypes.bool,
  disable: PropTypes.bool,
  textSize: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
  weight: PropTypes.oneOf(['light', 'normal', 'semibold', 'bold', 'bolder'])
};

export default Select;
